/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

const mock = [
  // independence-day-2023
  {   
    description:
      'Investiture 2024',
    href : '/investiture-2024'
 
  },
  {   
    description:
      'Orientation Program',
    href : '/orientation-programme-2024'
 
  },
  {   
    description:
      'International Yoga Day',
    href : '/yoga-day-2024'
 
  },
  {   
    description:
      'School Council 2024',
    href : '/school-council-2024'
 
  },
  {   
    description:
      'Goonj Event 2024',
    href : '/goonj-2024'
 
  },
  {   
    description:
      'No Bag Day',
    href : '/no-bag-day-2024'
 
  },
  {   
    description:
      'Dr. Maria Montessori Day',
    href : '/maria-montessori-day'
 
  },
  
];

const Sidebar2024 = () => {
  
  return (
    <Box component={Card} variant={'outlined'} padding={2}>
      
      <Typography
        variant="h6"
        data-aos={'fade-up'}
        sx={{
          fontWeight: 700,
          marginBottom: 2,
        }}
      >
        School Programs 2024-25
      </Typography>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12}>
            <Box
              component={Card}
              width={1}
              height={1}
              boxShadow={0}
              borderRadius={0}
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              sx={{ backgroundImage: 'none', bgcolor: 'transparent' }}
            >
              <Button href = {item.href}>
                <Typography component={'P'}>
                  {item.description}
                </Typography>
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Sidebar2024;
